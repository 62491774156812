import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import i18n from './i18n';

const setLanguageFromSubdomain = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split(':')[0]; // Assuming subdomain is the first part
  console.log(subdomain);
  switch (subdomain) {
    case 'localhost':
      i18n.changeLanguage('de');
      break;
    case '127.0.0.1':
      i18n.changeLanguage('en');
      break;
    default:
      i18n.changeLanguage('en');
  }
};


// Set language before rendering the app
setLanguageFromSubdomain();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
