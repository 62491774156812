import React, { useContext, useState } from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import { workExperiences } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

import ComponentModal from "../../components/achievementCard/ComponentModal";

export default function WorkExperience() {
  const { isDark } = useContext(StyleContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [CurrentComponent, setCurrentComponent] = useState(null);

  const handleOpenModal = (Component) => {
    setCurrentComponent(() => Component);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentComponent(null);
  };

  if (workExperiences.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">Services</h1>
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => (
                  <ExperienceCard
                    key={i}
                    isDark={isDark}
                    handleOpenModal={handleOpenModal}
                    cardInfo={card} // Pass the whole card information
                  />
                ))}
              </div>
            </div>
          </div>
        </Fade>
        <ComponentModal open={modalOpen} handleClose={handleCloseModal} Component={CurrentComponent} />
      </div>
    );
  }
  return null;
}