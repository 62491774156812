import React from 'react';
import Main from './containers/Main';


function App() {
  return (
      <div>
        <Main/>
        </div>
        
      
  );
}
export default App;