import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import emoji from "react-easy-emoji";

const resources = {
  de: {
    translation: {
      "greeting": "mat4nrg – Society for Materials and Energy Applications (GmbH) (MAT4NRG) ist eine Ausgründung aus drei Universitäten. Das Tätigkeitsfeld der mat4nrg GmbH liegt im Bereich der Materialherstellung, -charakterisierung und -anwendung. Wir bieten deren Herstellung und Charakterisierung sowie die Untersuchung möglicher Anwendungsfelder mit besonderem Fokus auf Materialien für Energieanwendungen an.",
      "skilltitle": "Was wir tun?",
      "skillsub":"Wir sind spezialisiert auf: Entwicklung, Prüfung und Anwendung von Komponenten für die stationäre und mobile Energieerzeugung und -speicherung, insbesondere für Batterien und Brennstoffzellen. Erzeugung und Speicherung von Energie aus erneuerbaren und alternativen Energiequellen. Energietechnik mit den Schwerpunkten Speicher-, Verfahrens- und Wärmetechnik sind für MAT4NRG von großer Bedeutung. Wir arbeiten an der ultraschallgestützten Herstellung von Nanomaterialien, der Charakterisierung von Materialien im Hinblick auf ihre chemischen, elektrischen und mechanischen Eigenschaften, der Entwicklung und Lizenzierung von Herstellungsverfahren und -prozessen für Polymere sowie keramische und metallische Nanomaterialien, Verbundwerkstoffe und Katalysatoren für katalytische Anwendungen mit Schwerpunkt Photokatalyse.  Sonochemische und sonoelektrochemische Methoden werden zur Herstellung von Materialien in Nanogröße für die Anwendung z.B. als Elektrokatalysatoren eingesetzt.Mat4nrg ist in Umweltanwendungen wie dem Abbau und biotechnologischen Aufschluss von Mikroplastik sowie im Bereich der mikrobiellen Brennstoffzellen tätig und verfügt über eine langjährige Expertise in der physikalischen Oberflächenanalyse und atmosphärischen Plasmaaktivierung von Materialien. Weitere relevante Aktivitäten sind die Oberflächenmodifikation von Keramik, Transporteigenschaften in oxidkeramischen Materialien und elektrochemische Abscheidungsmethoden sowie Korrosion.",
      "skill": emoji(
        "⚡Ultraschall (Herstellung von Nanomaterialien, Katalysatoren usw.).")+
        emoji("⚡Charakterisierung von Werkstoffen hinsichtlich ihrer chemischen, elektrischen und mechanischen Eigenschaften.")+
        emoji("⚡Entwicklung und Lizenzierung von Produktionsmethoden und -verfahren für Polymere, keramische und metallische Nanomaterialien sowie Verbundwerkstoffe und Katalysatoren für katalytische Anwendungen, Photokatalyse, Ultraschallchemie und Umwelttechnik.")+
        emoji("⚡Entwicklung, Prüfung und Anwendung von Komponenten für die stationäre und mobile Energieerzeugung und -speicherung, insbesondere für Batterien und Brennstoffzellen.")+
        emoji("⚡Elektromobilität.")+
        emoji("⚡Erzeugung und Speicherung von Energie aus erneuerbaren und alternativen Energiequellen.")+
        emoji("⚡Energietechnik.")+
        emoji("⚡Energiespeicherung.")+
        emoji("⚡Energy Process Engineering.")+
        emoji("⚡Energieverfahrenstechnik.")+
        emoji("⚡Entwicklung von elektronischen Schaltungen.")+
        emoji("⚡Entwicklung, Lizenzierung und Handel mit elektrischen, chemischen und elektrochemischen Sensoren.")+
        emoji("⚡Entwicklung von Prototypen und Herstellung von Kleinserien von Bauteilen.")+
        emoji("⚡Handel mit entwickelten Materialien sowie mit Ausgangsstoffen für deren Herstellung.")+
        emoji("⚡Erstellung von Gutachten, Berichten, Berechnungen und Simulationen zu allen oben genannten Anträgen.")+
        emoji("⚡Big Data in Material-, Energie- und Umweltwissenschaften."),
      "workexprole1":"Maßgeschneiderte Materialien",
      "workexpdesc1":"Wir verwenden alle modernen Methoden für die Vorbereitung von Materialien entsprechend den Kundenanforderungen. Anschließend erfolgt die thermische Sinterung (falls erforderlich) und die Kontrolle der chemischen sowie der Phasenreinheit.",
      "skilldescbullets1": "Gemischtes Oxid"+"Pecchini"+"Sol-gel"+"Sonochemie"+"Elektrochemie"+"Sono-Elektrochemie",
      "workexprole2":"Charakterisierung von Materialien nach Ihren Anforderungen",
      "workexpdesc2":"Chemische Reinheit der Materialien.",
      "skilldescbullets2":"Phasenreinheit von Materialien"+
        "Transporteigenschaften in kondensierter Materie"+
        "Diffusion in kondensierter Materie - Permeationsstudien (Sauerstoff, Wasserstoff, etc.)"+
        "Elektrochemische Eigenschaften bei Temperaturen von bis zu 1200 °C"+
        "Messungen der Leitfähigkeit"+
        "Impedanzspektroskopie"+
        "Thermochemische Eigenschaften"+
        "Thermische Ausdehnung"+
        "Thermische Zyklen bei erhöhten Temperaturen"+
        "Wärmeleitfähigkeit",
        "workexprole3":"Messungen der Materialeigenschaften",
        "workexpdesc3":"Beispiele für die Messungen",
        "skilldescbullets3": "Thermische Wechselbeanspruchung von Verbundwerkstoffen"+
        "Elektrophoretische Abscheidung (EPD)"+
        "Elektrophoretische Abscheidung (EPD) von Wärme- und Sauerstoffsperrschichten auf Legierungen und C/C-Verbundwerkstoffen."+
        "Charakterisierung einer Ni-Basislegierung mit EPD-Beschichtung nach thermischer Behandlung"+
        "Thermische Zyklen bis zu 1600°C",
      "bigProjecttitle":"Große Projekte",
      "bigProjectsub":"Einige unserer herausragenden Projekte",
      "bigprojectdesc":"Ökosystem der Kreislaufwirtschaft zur Rückgewinnung, Wiederverwertung und Wiederverwendung von F-Gasen, die zur Verringerung der Treibhausgase beitragen [LIFE Retradeables]"+
        "Aktion C2: Prototypen für die Identifizierung und das Recycling von F-Gasen"+
        "Dieses Projekt wurde mit Mitteln aus dem LIFE-Programm der Europäischen Union im Rahmen der Finanzhilfevereinbarung"+ "LIFE19 CCM/AT 001226 - LIFE Retradeables",
        "achievementtitle1": "Forschungsbereiche",
    "t1": "Katalyse",
    "t2": "Katalysatorenherstellung",
    "t3": "Photokatalyse",
    "t4": "Elektrokatalyse",
    "t5": "Katalytische Tests",
    "t6": "Energieanwendungen",
    "t7": "Neue Materialien für Energieanwendungen",
    "t8": "Festoxid-Brennstoffzellen (SOFCs)",
    "t9": "Mikrobielle Brennstoffzellen (MFCs)",
    "t10": "Energiespeicherung",
    "t11": "CO2-Umwandlung zu Brennstoffen und Chemikalien",
    "t12": "Umwelt",
    "t13": "Kunststoffabbau",
    "t14": "Fortgeschrittene Oxidationsprozesse (AOPs)",
    "t15": "Mikrobielle FCs",
    "footer": "Kontaktieren Sie uns",
    "legalNoticeTitle": "Rechtlicher Hinweis",
      "address1": "mat4nrg GmbH",
      "address2": "Burgstätterstr. 42",
      "address3": "38678 Clausthal-Zellerfeld",
      "email": "info@mat4nrg.de",
      "representedBy": "Vertreten durch: Nikolaos Argirusis",
      "databaseEntry": "Eintrag in die Datenbank:",
      "registeredIn": "Eingetragen im Handelsregister.",
      "registryCourt": "Registergericht: Braunschweig",
      "registrationNumber": "Registernummer: HRB 207892",
      "vatID": "UMSATZSTEUER-ID:",
      "vatNumber": "Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE325122921",
      "disclaimerTitle": "Haftungsausschluss - rechtliche Hinweise",
      "contentWarning": "Warnung vor dem Inhalt:",
      "contentWarningText": "Die kostenlosen und frei zugänglichen Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Website übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratschläge und Nachrichten. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Der einfache Zugriff auf die kostenlosen und frei zugänglichen Inhalte kommt kein Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, da der Anbieter keine Absicht hat, rechtlich gebunden zu sein.",
      "externalLinks": "Externe Links:",
      "externalLinksText": "Diese Website enthält Verknüpfungen zu Websites Dritter ('externe Links'). Diese Websites unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu diesem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verlinkten Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen sind jedoch derartige externe Links werden sofort gelöscht.",
      "copyright": "Urheber- und Leistungsschutzrechte:",
      "copyrightText": "Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede Verwendung nicht erlaubt nach dem deutschen Urheber- und Leistungsschutzrecht bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder den jeweiligen Rechteinhaber. Dies gilt für insbesondere die Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systeme. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder ganze Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen Gebrauch ist erlaubt, die private und nicht kommerzielle Nutzung ist erlaubt. Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.",
      "specialConditions": "Besondere Bedingungen für die Nutzung:",
      "specialConditionsText": "Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an dieser Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.",
      "source": "Quelle: Impressumsbeispiel aus JuraForum.de",
      "dataProtection": "Datenschutz:",
      "dataProtectionText": "Im Folgenden informieren wir Sie gemäß den gesetzlichen Bestimmungen des Datenschutzrechts (insbesondere gemäß BDSG n.F. und der Europäischen Datenschutz-Grundverordnung (DS-GVO) über Art, Umfang und Zweck der Verarbeitung von personenbezogenen Daten durch unser Unternehmen. Diese Datenschutzrichtlinie gilt auch für unsere Websites und Social-Media-Profile. Bezüglich der Definition von Begriffen wie 'personenbezogene Daten' oder 'Verarbeitung', verweisen wir auf Art. 4 GDPR. Name und Kontaktdaten der verantwortlichen Person",
      "controller": "Unser Verantwortlicher (im Folgenden 'Verantwortlicher') im Sinne des Art. 4 Nr. 7 GDPR ist:",
      "dataTypes": "Arten von Daten, Zwecke der Verarbeitung und Kategorien betroffener Personen",
      "novelMaterialsIntro": "Heutzutage besteht ein enormer Bedarf an neuartigen Materialien für ein breites Spektrum von Energieanwendungen. Anwendungsbereiche können sein:",
      "hydrogenProduction": "Wasserstofferzeugung und -speicherung",
      "fuelCellTechnology": "Brennstoffzellen-Technologie",
      "solarEnergy": "Solarenergie",
      "windEnergy": "Windenergie",
      "energyEfficiencyBuildings": "Steigerung der Energieeffizienz in Gebäuden und Integration alternativer Energiequellen",
      "powerPlantEfficiency": "Steigerung der Effizienz von Kraftwerken",
      "geothermalApplications": "Geothermische Anwendungen",
      "pumpedStorage": "Pumpspeicherkraftwerke in der Wasserkraft",
      "k":"Kontakt",
    }
  },
 en: {
    translation: {
      "greeting": "mat4nrg – Society for Materials and Energy Applications (GmbH) (MAT4NRG) is a spin-off from three universities.The field of activity of mat4nrg GmbH is in the field of materials preparation, characterization and application. We offer their production and characterization as well as the investigation of possible fields of application with special focus on materials for energy applications.",
      "skilltitle": "What we do?",
      "skillsub":"We are specializing on: Development, testing and application of components for stationary and mobile energy production and storage, in particular for batteries and fuel cells. Production and storage of energy from renewable and alternative energy sources. Energy technology with focus on storage, process and thermal engineering are of great importance for MAT4NRG. We are working on ultrasound assisted production of nanomaterials, the characterization of materials with regard to their chemical, electrical and mechanical properties, development and licensing of production methods and processes for polymers as well as ceramic and metallic nanomaterials, composite materials and catalysts for catalytic applications with focus on photocatalysis.  Sonochemical and sono-electrochemical methods are used for the preparation of nanosized materials for application e.g. as electrocatalysts.Mat4nrg is active in environmental applications such as the degradation and biotechnological digestion of microplastics as well as in the field of microbial fuel cells.Last but not least, we have long expertise in the physical surface analysis and atmospheric plasma activation of materials. Other relevant activities are surface modification of ceramics, transport properties in oxide ceramic materials and electrochemical deposition methods as well as corrosion.",
      "skill": emoji(
        "⚡Ultrasonics (production of nanomaterials, catalysts, etc.).")+
        emoji("⚡Characterization of materials with regard to their chemical, electrical and mechanical properties.")+
        emoji("⚡Development and licensing of production methods and processes for polymers as well as ceramic and metallic nanomaterials as well as composite materials and catalysts for catalytic applications, photocatalysis, ultrasound chemistry and environmental technology.")+
        emoji("⚡Development, testing and application of components for stationary and mobile energy production and storage, in particular for batteries and fuel cells.")+
        emoji("⚡Electric Mobility.")+
        emoji("⚡Production and storage of energy from renewable and alternative energy sources.")+
        emoji("⚡Energy Technology.")+
        emoji("⚡Energy storage.")+
        emoji("⚡Energy Process Engineering.")+
        emoji("⚡Thermal Engineering.")+
        emoji("⚡Development of electronic circuits.")+
        emoji("⚡Development, licensing and trading electrical, chemical and electrochemical sensors.")+
        emoji("⚡Prototype development and small batch production of components.")+
        emoji("⚡Trade with developed materials as well as starting materials for their production.")+
        emoji("⚡Preparation of expertise opinions, reports, calculations and simulations to all of the above mentioned applications.")+
        emoji("⚡Big Data in Materials, Energy and Environmental Sciences."),
      "workexprole1":"Tailor made materials",
      "workexpdesc1": "We use all state-of-the-art methods for the preparation of materials according to customers demands. All followed by thermal sintering (if necessary) and control of chemical as well as phase purity.",
      "skilldescbullets1":"Mixed oxide"+"Pecchini"+"Sol-gel"+"Sonochemistry"+ "Electrochemistry"+ "Sono-electrochemistry",
      "workexprole2":"Characterization of materials according to your demands",
      "workexpdesc2":"Chemical purity of materials.",
      "skilldescbullets2":"Phase purity of materials"+
        "Transport properties in condensed matter"+
        "Diffusion in condensed matter-Permeation studies (oxygen, hydrogen, etc)"+
        "Electrochemical properties at temperatures up to 1200 °C"+
        "Conductivity measurements"+
        "Impedance spectroscopy"+
        "Thermochemical properties"+
        "Thermal expansion"+
        "Thermal cycling at elevated temperatures"+
        "Thermal conductivity",
      "workexprole3":"Materials Properties Measurements",
      "workexpdesc3":"Examples of the Measurements",
      "skilldescbullets3":"Thermal cycling of composite materials"+
        "Electrophoretic deposition(EPD)"+
        "Electrophoretic deposition(EPD) of thermal and oxygen barrier coatings on alloys and C/C composites."+
        "Characterisation of Ni-based alloy with EPD coating after thermal treatment"+
        "Thermal cycling facilities up to 1600°C",
      "bigProjecttitle":"Big Projects",
      "bigProjectsub": "Some of our prominent projects",
      "bigprojectdesc":"Circular economy ecosystem to Recover, Recycle and Re-use F-gases contributing to the depletion of greenhouse gases [LIFE Retradeables]"+
        "Action C2: F-gas identification & recycling prototypes"+
        "This project has received funding from the LIFE Programme of the European Union under grant agreement"+"LIFE19 CCM/AT 001226 - LIFE Retradeables",
      "achievementtitle1":"Reasearch Fields",
      "t1":"Catalysis",
      "t2":"Catalysts Preperation",
      "t3":"Photocatalysis",
      "t4":"Electrocatalysis",
      "t5":"Catalytic Tests",
      "t6":"Energy Applications",
      "t7":"Novel Materials for Energy Applications",
      "t8":"Solid Oxide Fuel Cells (SOFCs)",
      "t9":"Microbial Fuel Cells (MFCs)", 
      "t10":"Energy Storage",
      "t11":"CO2 Conversion to Fuels and Chemicals",
      "t12":"Environment",
      "t13":"Plastics Degradation",
      "t14":"Advanced Oxidation Processes (AOPs)",
      "t15":"Microbial FCs",
      "footer": "Contact Us",
      "legalNoticeTitle": "Legal Notice",
      "address1": "mat4nrg GmbH",
      "address2": "Burgstätterstr. 42",
      "address3": "38678 Clausthal-Zellerfeld",
      "email": "info@mat4nrg.de",
      "representedBy": "Represented by: Nikolaos Argirusis",
      "databaseEntry": "Database Entry:",
      "registeredIn": "Registered in the commercial register.",
      "registryCourt": "Registry Court: Braunschweig",
      "registrationNumber": "Registration Number: HRB 207892",
      "vatID": "VAT ID:",
      "vatNumber": "VAT Identification Number according to § 27a VAT Act: DE325122921",
      "disclaimerTitle": "Disclaimer - Legal Notice",
      "contentWarning": "Warning about content:",
      "contentWarningText": "The free and freely accessible content of this website has been created with the greatest possible care. However, the provider of this website assumes no responsibility for the accuracy and timeliness of the free and freely accessible journalistic advice and news provided. Contributions identified by name reflect the opinion of the respective author and not always the opinion of the provider. The mere access to the free and freely accessible content does not create any contractual relationship between the user and the provider, as the provider has no intention to be legally bound.",
      "externalLinks": "External Links:",
      "externalLinksText": "This website contains links to third-party websites ('external links'). These websites are subject to the liability of the respective operators. When the external links were first linked, the provider checked the external content to see if there were any legal violations. At that time, no legal violations were apparent. The provider has no influence on the current and future design and content of the linked pages. The inclusion of external links does not mean that the provider adopts the content behind the reference or link as its own. Constant monitoring of these external links is not reasonable for the provider without concrete indications of legal violations. If legal violations become known, such external links will be deleted immediately.",
      "copyright": "Copyright and Performance Rights:",
      "copyrightText": "The content published on this website is subject to German copyright and performance rights. Any use not permitted by German copyright and performance rights law requires the prior written consent of the provider or the respective rights holder. This applies in particular to the reproduction, editing, translation, storage, processing, or reproduction of content in databases or other electronic media and systems. Third-party content and rights are marked as such. The unauthorized reproduction or distribution of individual content or complete pages is not permitted and punishable. Only the production of copies and downloads for personal, private, and non-commercial use is permitted. The presentation of this website in external frames is only allowed with written permission.",
      "specialConditions": "Special conditions of use:",
      "specialConditionsText": "Insofar as special conditions for individual uses of this website deviate from the aforementioned paragraphs, this is explicitly pointed out at the appropriate place. In this case, the special conditions of use apply in the respective individual case.",
      "source": "Source: Example imprint from JuraForum.de",
      "dataProtection": "Data Protection:",
      "dataProtectionText": "In the following, we inform you according to the legal requirements of data protection law (especially according to the Federal Data Protection Act (BDSG n.F.) and the European General Data Protection Regulation (GDPR)) about the type, scope, and purpose of the processing of personal data by our company. This privacy policy also applies to our websites and social media profiles. Regarding the definition of terms such as 'personal data' or 'processing,' we refer to Art. 4 GDPR. Name and contact details of the responsible person",
      "controller": "Our responsible person (hereinafter 'controller') in the sense of Art. 4 No. 7 GDPR is:",
      "dataTypes": "Types of data, purposes of processing, and categories of affected persons",
      "novelMaterialsIntro": "There is an enormous demand for novel materials for a wide range of energy applications today. Application areas may include:",
      "hydrogenProduction": "Hydrogen production and storage",
      "fuelCellTechnology": "Fuel cell technology",
      "solarEnergy": "Solar energy",
      "windEnergy": "Wind energy",
      "energyEfficiencyBuildings": "Increasing energy efficiency in buildings and integrating alternative energy sources",
      "powerPlantEfficiency": "Increasing the efficiency of power plants",
      "geothermalApplications": "Geothermal applications",
      "pumpedStorage": "Pumped storage power plants in hydropower",
      "k":"Contact"
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // if you want to use a cookie or save the language globally, you can determine it dynamically here
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
