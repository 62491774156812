
import React, {useContext, useState} from "react";
import "./StartupProjects.scss";
import {bigProjects} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import ComponentModal from "../../components/achievementCard/ComponentModal.js"; // Ensure to import your modal component

export default function StartupProject() {
  const {isDark} = useContext(StyleContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [CurrentComponent, setCurrentComponent] = useState(null);

  if (!bigProjects.display) {
    return null;
  }

  const handleOpenModal = Component => {
    setCurrentComponent(() => Component);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentComponent(null);
  };

  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="projects">
        <div>
          <h1 className="skills-heading">{bigProjects.title}</h1>
          <p
            className={
              isDark
                ? "dark-mode project-subtitle"
                : "subTitle project-subtitle"
            }
          >
            {bigProjects.subtitle}
          </p>
          <div className="projects-container">
            {bigProjects.projects.map((project, i) => {
              const ProjectComponent = () => (
                <div>
                  {project && (
                    <div className="project-image">
                      <a href={project.footerLink[0].url} target="blank">
                        <img
                          src={project.image}
                          alt={project.projectName}
                          className="card-image"
                        />
                      </a>
                    </div>
                  )}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>
                    <div className="project-description">
                      <div>
                        {project.projectDescs.map((desc, index) => (
                          <p key={index} className="project-desc-text">
                            {formatProjectDesc(desc)}
                          </p>
                        ))}
                      </div>
                      {project.additionalImage && (
                        <img
                          src={project.additionalImage}
                          alt={project.projectName + " additional"}
                          className="additional-image right-align"
                        />
                      )}
                    </div>
                  </div>
                  {project.footerLink && (
                    <div className="project-card-footer">
                      {project.footerLink.map((link, i) => (
                        <span
                          key={i}
                          className={
                            isDark ? "dark-mode project-tag" : "project-tag"
                          }
                          onClick={() => openUrlInNewTab(link.url)}
                        >
                          {link.name}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              );
              return (
                <div
                  key={i}
                  className={
                    isDark
                      ? "dark-mode project-card project-card-dark"
                      : "project-card project-card-light"
                  }
                  onClick={() => handleOpenModal(ProjectComponent)}
                >
                  {project && (
                    <div className="project-image">
                      <a href={project.footerLink[0].url} target="blank">
                        <img
                          src={project.image}
                          alt={project.projectName}
                          className="card-image"
                        />
                      </a>
                    </div>
                  )}
                  <div className="project-detail">
                    <h5
                      className={isDark ? "dark-mode card-title" : "card-title"}
                    >
                      {project.projectName}
                    </h5>
                    <div className="project-description">
                      <div>
                        {project.projectDescs.map((desc, index) => (
                          <p key={index} className="project-desc-text">
                            {formatProjectDesc(desc)}
                          </p>
                        ))}
                      </div>
                      {project.additionalImage && (
                        <img
                          src={project.additionalImage}
                          alt={project.projectName + " additional"}
                          className="additional-image right-align"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ComponentModal
        open={modalOpen}
        handleClose={handleCloseModal}
        Component={CurrentComponent}
      />
    </Fade>
  );
}

// Helper function to format the project description
function formatProjectDesc(desc) {
  const boldPart = "LIFE Retradeables";
  const parts = desc.split(`[${boldPart}]`);
  return (
    <>
      {parts[0]}
      <strong>{boldPart}</strong>
      {parts[1]}
    </>
  );
}
