import React from "react";
import "./App.scss";
import { Link } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import SplashScreen from './containers/splashScreen/SplashScreen';
import ScrollToTopButton from "./containers/topbutton/Top";


const LegalNotice = () => {

  return (
    
    <div style={{ height: '100%', overflow: 'scroll' }}> 
      <a href="/">
    <img  src={require("../src/components/header/logo.png")} alt={"Test"} width="100px" height="50px"  />
    </a>
      <h1>Rechtlicher Hinweis</h1>
    <h3>mat4nrg GmbH</h3>
    <h3>Burgstätterstr. 42</h3>
    <h3>38678 Clausthal-Zellerfeld</h3>
    <h3>info@mat4nrg.de</h3>
    <h3>Vertreten durch: Nikolaos Argirusis</h3>
    
    <h2>Eintrag in die Datenbank:</h2>
    <h3>Eingetragen im Handelsregister.</h3>
    <h3>Registergericht: Braunschweig</h3>
    <h3>Registernummer: HRB 207892</h3>
    
    <h2>UMSATZSTEUER-ID:</h2>
    <h3>Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE325122921</h3>
    
    <h2>Haftungsausschluss - rechtliche Hinweise</h2>
    <p><b>Warnung vor dem Inhalt:</b>
    Die kostenlosen und frei zugänglichen Inhalte dieser Website wurden mit größtmöglicher Sorgfalt erstellt.<br /> Der Anbieter dieser Website übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten kostenlosen und frei zugänglichen<br/> journalistische Ratschläge und Nachrichten.<br/> Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Der einfache Zugriff auf die kostenlose<br/> und frei zugänglichen Inhalten kommt kein Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, da der Anbieter keine Absicht hat<br/> rechtlich gebunden zu sein.
    <br/><b>Externe Links:</b>
    Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der<br/> die Haftung<br/> der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. <br/>Inhalt, um zu sehen, ob es irgendwelche rechtlichen<br/> Verstöße. Zu diesem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. <br/>der verlinkten Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht.<br/> Eine ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen sind jedoch derartige externe<br/> Links werden sofort gelöscht.
    <br/><b>Urheber- und Leistungsschutzrechte:</b>
    Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede <br/>Verwendung nicht erlaubt<br/> nach dem deutschen Urheber- und Leistungsschutzrecht bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder <br/>den jeweiligen Rechteinhaber. Dies gilt für <br/>insbesondere die Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien<br/> Medien und Systeme. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte <br/>oder ganze Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen Gebrauch ist erlaubt, <br/>die private und nicht kommerzielle Nutzung ist erlaubt.
    Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
    <br/><b>Besondere Bedingungen für die Nutzung:</b>
    Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird an dieser Stelle ausdrücklich darauf hingewiesen.<br/> wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen .<br/>
    Quelle: Impressumsbeispiel aus JuraForum.de</p>
    
    <h2>Datenschutz:</h2>
    
    <p>Im Folgenden informieren wir Sie gemäß den gesetzlichen Bestimmungen des Datenschutzrechts (insbesondere<br/> gemäß BDSG n.F. und der Europäischen Datenschutz-Grundverordnung (DS-GVO) über Art, Umfang und<br/> und Zweck der Verarbeitung von personenbezogenen Daten durch unser Unternehmen. Diese Datenschutzrichtlinie gilt auch für unsere Websites und Social-Media-Profile. Bezüglich der Definition von Begriffen wie <br/>"personenbezogene Daten" oder "Verarbeitung", verweisen wir auf Art. 4 GDPR. Name und Kontaktdaten der verantwortlichen Person
    <br/>Unser Verantwortlicher (im Folgenden "Verantwortlicher") im Sinne des Art. 4 Nr. 7 GDPR ist:</p><br/>
    
    <h3>mat4nrg GmbH</h3>
    <h3>Burgstätterstr. 42</h3>
    <h3>38678 Clausthal-Zellerfeld</h3>
    <h3>info@mat4nrg.de</h3>
    <h3>Vertreten durch: Nikolaos Argirusis</h3>
    
    <b>Arten von Daten, Zwecke der Verarbeitung und Kategorien betroffener Personen</b>
      </div>
  );
  
}

export default LegalNotice;
/*import React from "react";
import "./App.scss";
import { Link } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import SplashScreen from './containers/splashScreen/SplashScreen';
import ScrollToTopButton from "./containers/topbutton/Top";
import { useTranslation } from 'react-i18next';

const LegalNotice = () => {
  const { t } = useTranslation();

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      <a href="/">
        <img src={require("../src/components/header/logo.png")} alt={"Test"} width="100px" height="50px" />
      </a>
      <h1>{t('legalNoticeTitle')}</h1>
      <h3>{t('address1')}</h3>
      <h3>{t('address2')}</h3>
      <h3>{t('address3')}</h3>
      <h3>{t('email')}</h3>
      <h3>{t('representedBy')}</h3>
      
      <h2>{t('databaseEntry')}</h2>
      <h3>{t('registeredIn')}</h3>
      <h3>{t('registryCourt')}</h3>
      <h3>{t('registrationNumber')}</h3>
      
      <h2>{t('vatID')}</h2>
      <h3>{t('vatNumber')}</h3>
      
      <h2>{t('disclaimerTitle')}</h2>
      <p>
        <b>{t('contentWarning')}</b>
        {t('contentWarningText')}
        <br /><b>{t('externalLinks')}</b>
        {t('externalLinksText')}
        <br /><b>{t('copyright')}</b>
        {t('copyrightText')}
        <br /><b>{t('specialConditions')}</b>
        {t('specialConditionsText')}
        {t('source')}
      </p>
      
      <h2>{t('dataProtection')}</h2>
      <p>
        {t('dataProtectionText')}
        <br />{t('controller')}
      </p>
      <h3>{t('address1')}</h3>
      <h3>{t('address2')}</h3>
      <h3>{t('address3')}</h3>
      <h3>{t('email')}</h3>
      <h3>{t('representedBy')}</h3>
      <b>{t('dataTypes')}</b>
    </div>
  );
}

export default LegalNotice;
*/

