/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file
import { Link } from 'react-router-dom';
import LegalNotice from './LegalNotice';
import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/mymy.json"; // Rename to your file name for custom animation
import Component1 from '../src/components/achievementCard/Component1.js';
import Catalyst from './components/achievementCard/Catalyst.js';
import photoCatalyst from './components/achievementCard/photoCatalyst.js';
import electroCatalyst from './components/achievementCard/electroCatalyst.js';
import testCatalyst from './components/achievementCard/testCatalyst.js';
import plus from './components/achievementCard/plus.js';
import NovelMaterialsforEnergyApplications from './components/achievementCard/NovelMaterialsforEnergyApplications.js';


// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "MAT4NRJ",
  title: "MAT4NRG",
  subTitle: (
    "mat4nrg – Society for Materials and Energy Applications (GmbH) ist eine Ausgründung aus drei Universitäten. Das Tätigkeitsfeld der mat4nrg GmbH liegt im Bereich der Materialherstellung, -charakterisierung und -anwendung. Wir bieten deren Herstellung und Charakterisierung sowie die Untersuchung möglicher Anwendungsfelder mit besonderem Fokus auf Materialien für Energie- und Umwelttechnik an."),
  resumeLink:false, // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "",
  linkedin: "",
  gmail: "",
  gitlab: "",
  facebook: "",
  medium: "",
  stackoverflow: "",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: false // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Was wir tun?",
  subTitle: "Wir sind spezialisiert auf: Entwicklung, Prüfung und Anwendung von Komponenten für die stationäre und mobile Energieerzeugung und -speicherung, insbesondere für Batterien und Brennstoffzellen. Erzeugung und Speicherung von Energie aus erneuerbaren und alternativen Energiequellen. Energietechnik mit den Schwerpunkten Speicher-, Verfahrens- und Wärmetechnik sind für MAT4NRG von großer Bedeutung. Wir arbeiten an der ultraschallgestützten Herstellung von Nanomaterialien, der Charakterisierung von Materialien im Hinblick auf ihre chemischen, elektrischen und mechanischen Eigenschaften, der Entwicklung und Lizenzierung von Herstellungsverfahren und -prozessen für Polymere sowie keramische und metallische Nanomaterialien, Verbundwerkstoffe und Katalysatoren für katalytische Anwendungen mit Schwerpunkt Photokatalyse.  Sonochemische und sonoelektrochemische Methoden werden zur Herstellung von Materialien in Nanogröße für die Anwendung z.B. als Elektrokatalysatoren eingesetzt.Mat4nrg ist in Umweltanwendungen wie dem Abbau und biotechnologischen Aufschluss von Mikroplastik sowie im Bereich der mikrobiellen Brennstoffzellen tätig und verfügt über eine langjährige Expertise in der physikalischen Oberflächenanalyse und atmosphärischen Plasmaaktivierung von Materialien. Weitere relevante Aktivitäten sind die Oberflächenmodifikation von Keramik, Transporteigenschaften in oxidkeramischen Materialien und elektrochemische Abscheidungsmethoden sowie Korrosion.",
  skills: [
    emoji(
      "⚡Ultraschall (Herstellung von Nanomaterialien, Katalysatoren usw.)."),
      emoji("⚡Charakterisierung von Werkstoffen hinsichtlich ihrer chemischen, elektrischen und mechanischen Eigenschaften."),
      emoji("⚡Entwicklung und Lizenzierung von Produktionsmethoden und -verfahren für Polymere, keramische und metallische Nanomaterialien sowie Verbundwerkstoffe und Katalysatoren für katalytische Anwendungen, Photokatalyse, Ultraschallchemie und Umwelttechnik."),
      emoji("⚡Entwicklung, Prüfung und Anwendung von Komponenten für die stationäre und mobile Energieerzeugung und -speicherung, insbesondere für Batterien und Brennstoffzellen."),
      emoji("⚡Elektromobilität."),
      emoji("⚡Erzeugung und Speicherung von Energie aus erneuerbaren und alternativen Energiequellen."),
      emoji("⚡Energietechnik."),
      emoji("⚡Energiespeicherung."),
      emoji("⚡Energy Process Engineering."),
      emoji("⚡Energieverfahrenstechnik."),
      emoji("⚡Entwicklung von elektronischen Schaltungen."),
      emoji("⚡Entwicklung, Lizenzierung und Handel mit elektrischen, chemischen und elektrochemischen Sensoren."),
      emoji("⚡Entwicklung von Prototypen und Herstellung von Kleinserien von Bauteilen."),
      emoji("⚡Handel mit entwickelten Materialien sowie mit Ausgangsstoffen für deren Herstellung."),
      emoji("⚡Erstellung von Gutachten, Berichten, Berechnungen und Simulationen zu allen oben genannten Gebieten."),
      emoji("⚡Big Data in Material-, Energie- und Umweltwissenschaften."),
  
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      //skillName: "html-5",
      //fontAwesomeClassname: "fab fa-html5"
    },
    {
      //skillName: "css3",
      //fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      //skillName: "sass",
      //fontAwesomeClassname: "fab fa-sass"
    },
    {
      //skillName: "JavaScript",
      //fontAwesomeClassname: "fab fa-js"
    },
    {
      //skillName: "reactjs",
      //fontAwesomeClassname: "fab fa-react"
    },
    {
      //skillName: "nodejs",
      //fontAwesomeClassname: "fab fa-node"
    },
    {
      //skillName: "swift",
      //fontAwesomeClassname: "fab fa-swift"
    },
    {
      //skillName: "npm",
      //fontAwesomeClassname: "fab fa-npm"
    },
    {
      //skillName: "sql-database",
      ///fontAwesomeClassname: "fas fa-database"
    },
    {
      //skillName: "aws",
      //fontAwesomeClassname: "fab fa-aws"
    },
    {
      //skillName: "firebase",
      //fontAwesomeClassname: "fas fa-fire"
    },
    {
      //skillName: "python",
      //fontAwesomeClassname: "fab fa-python"
    },
    {
      //skillName: "docker",
      //fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Harvard University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2017 - April 2019",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section
// portfolio.js
// portfolio.js
const workExperiences = {
  display: true, // Set it to true to show the work experiences section
  experience: [
    {
      role: "Maßgeschneiderte Materialien",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      desc: "Wir verwenden alle modernen Methoden für die Vorbereitung von Materialien entsprechend den Kundenanforderungen. Anschließend erfolgt die thermische Sinterung (falls erforderlich) und die Kontrolle der chemischen sowie der Phasenreinheit.",
      descBullets: [
        "Gemischtes Oxid","Pecchini","Sol-gel","Sonochemie","Elektrochemie","Sono-Elektrochemie"
      ],
    },
    {
      role: "Charakterisierung von Materialien nach Ihren Anforderungen",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      date: "",
      desc: "Chemische Reinheit der Materialien.",
      descBullets: [
        "Phasenreinheit von Materialien",
        "Transporteigenschaften in kondensierter Materie",
        "Diffusion in kondensierter Materie - Permeationsstudien (Sauerstoff, Wasserstoff, etc.)",
        "Elektrochemische Eigenschaften bei Temperaturen von bis zu 1200 °C",
        "Messungen der Leitfähigkeit",
        "Impedanzspektroskopie",
        "Thermochemische Eigenschaften",
        "Thermische Ausdehnung",
        "Thermische Zyklen bei erhöhten Temperaturen",
        "Wärmeleitfähigkeit",
      ],
      // This one does not have popupImages
    },
    {
      role: "Messungen der Materialeigenschaften",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      date: "",
      desc: "Beispiele für die Messungen",
      descBullets: [
        "Thermische Wechselbeanspruchung von Verbundwerkstoffen",
        "Elektrophoretische Abscheidung (EPD)",
        "Elektrophoretische Abscheidung (EPD) von Wärme- und Sauerstoffsperrschichten auf Legierungen und C/C-Verbundwerkstoffen.",
        "Charakterisierung einer Ni-Basislegierung mit EPD-Beschichtung nach thermischer Behandlung",
        "Thermische Zyklen bis zu 1600°C"
      ],
      popupImages: [ // Optional additional images
        require("./assets/images/image-3-1024x541.png"),
        require("./assets/images/image-5-1024x618.png"),
        require("./assets/images/image-6-1024x597.png"),
        require("./assets/images/image-7.png"),
      ]
    },
    // Add more experiences as needed...
  ]
};
/*
const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Tailor made materials",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      desc: "We use all state-of-the-art methods for the preparation of materials according to customers demands.All followed by thermal sintering (if necessary) and control of chemical as well as phase purity",
      descBullets: [
        "Mixed oxide",
        "Pecchini",
        "Sol-gel",
        "Sonochemistry",
        "Electrochemistry",
        "Sono-electrochemistry",
      ],
    },
    {
      role: "Characterization of materials according to your demands",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      date: "",
      desc: "Chemical purity of materials :",
      descBullets: [
       "Phase purity of materials","Transport properties in condensed matter",
       " Diffusion in condensed matter-Permeation studies (oxygen, hydrogen, etc)",
       "Electrochemical properties at temepratures up to 1200 °C",
       "Conductivity measurements",
       "Impedance spectroscopy",
       "Thermochemical properties",
       "Thermal expansion",
       "Thermal cycling at elevated temperatures-Thermal conductivity."]
    },
    {
      role: "Materials Properties Measurements",
      company: "",
      companylogo: require("./assets/images/becher.png"),
      date: "",
      desc: "Examples of the Measurements",
      descBullets: ["Thermal cycling of composite materials",
      "Electrophoretic deposition(EPD)",
      "Electrophoretic deposition(EPD) of thermal and oxygen barrier coatings on alloys and C/C composites.",
       "Characterisation of Ni-based alloy with EPD coating after thermal treatment",
      "Thermal cycling facilities up to 1600°C."]
    }
  ]
};*/
// portfolio.js


/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

/*const bigProjects = {
  title: "Research Projects",
  subtitle: "Some projects that we worked on",
  projects: [
    {
      image: require("./assets/images/pic2.png"),
      projectName: "project name",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "explore more",
          url: "link"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/pic2.png"),
      projectName: "project name",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "explore more",
          url: "link"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/pic2.png"),
      projectName: "Project name",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "explore more",
          url: "explore"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/pic2.png"),
      projectName: "Nproject name",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "explore more",
          url: "explore more"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};*/
// portfolio.js
/*const bigProjects = {
  display: true, // Set false to hide this section, defaults to true if omitted.
  title: "Big Projects",
  subtitle: "Some of my prominent projects",
  projects: [
    {
      image: require("./assets/images/retreadables.png"),
      projectName: "Retradeables",
      projectDesc: "Circular economy ecosystem to Recover, Recycle and Re-use F-gases contributing to the depletion of greenhouse gases[LIFE Retradeables]",
      footerLink: [
        { name: "Link1", url: "" },
        { name: "GitHub", url: "" }
      ]
    }
    // More projects...
  ]
};*/
const bigProjects = {
  display: true, // Set false to hide this section, defaults to true if omitted.
  title: "Projekte",
  subtitle: "Einige unserer herausragenden Projekte",
  projects: [
    {
      image: require("./assets/images/retreadables.png"),
      projectName: "",
      projectDescs: [
        "Ökosystem der Kreislaufwirtschaft zur Rückgewinnung, Wiederverwertung und Wiederverwendung von F-Gasen, die zur Verringerung der Treibhausgase beitragen [LIFE Retradeables]",
        "Aktion C2: Prototypen für die Identifizierung und das Recycling von F-Gasen",
        "Dieses Projekt wurde mit Mitteln aus dem LIFE-Programm der Europäischen Union im Rahmen der Finanzhilfevereinbarung","LIFE19 CCM/AT 001226 - LIFE Retradeables"
      ],
      additionalImage: require("./assets/images/Image1.png"), // Add this line
      footerLink: [
        { name: "Retadeables Link", url: "https://retradeables.com" },
        { name: "", url: "" }
      ]
    },
    // More projects...
  ]
};
const achievementSection = {
  display: true,
  title: "Forschungsbereiche",
  subtitle: "",
  achievementsCards: [
    {
      title: "Katalyse",
      subtitle: "",
      image: require("./assets/images/becher.png"),
      imageAlt: "Achievement Image Alt",
      footerLink1: [
        { name: "Katalysatorenherstellung", component: Catalyst },
      ],
      footerLink2: [
        { name: "Photokatalyse", component: photoCatalyst },
      ],
      footerLink3: [
        { name: "Elektrokatalyse", component: electroCatalyst },
      ],
      footerLink4: [
        { name: "Katalytische Tests", component: testCatalyst },
      ],
      footerLink5: [
        { name: "+", component: plus },
      ],
    },
    {
      title: "Energieanwendungen",
      subtitle: "",
      image: require("./assets/images/becher.png"),
      imageAlt: "",
      footerLink1: [
        { name: "Neue Materialien für Energieanwendungen", component: NovelMaterialsforEnergyApplications},
      ],
      footerLink2: [
        { name: "Festoxid-Brennstoffzellen (SOFCs)", component: photoCatalyst },
      ],
      footerLink3: [
        { name: "Mikrobielle Brennstoffzellen (MFCs)", component: photoCatalyst },
      ],
      footerLink4: [
        { name: "Energiespeicherung", component: photoCatalyst },
      ],
      footerLink5: [
        { name: "CO2-Umwandlung zu Brennstoffen und Chemikalien", component: photoCatalyst },
      ],
    },
    {
      title: "Umwelt",
      subtitle: "",
      image: require("./assets/images/becher.png"),
      imageAlt: "",
      footerLink1: [
        { name: "Kunststoffabbau", component: photoCatalyst },
      ],
      footerLink2: [
        { name: "Fortgeschrittene Oxidationsprozesse (AOPs)", component: photoCatalyst },
      ],
      footerLink3: [
        { name: "Mikrobielle FCs", component: photoCatalyst },
      ],
      footerLink4: [
        { name: "+", component: plus },
      ],
      footerLink5: [
        { name: "+", component: plus },
      ],
    },
  ],
};
/*const achievementSection = {
  display: true,
  title: "Achievement Section Title",
  subtitle: "Some subtitle",
  achievementsCards: [
    {
      title: "Achievement Title 1",
      subtitle: "Achievement Subtitle 1",
      image: "path_to_image",
      imageAlt: "Achievement Image Alt",
      footerLink1: [
        { name: "Link1", component: Component1 },
      ],
      footerLink2: [
        { name: "Link2", component: Component1 },
      ],
      footerLink3: [
        { name: "Link3", component: Component1 },
      ],
      footerLink4: [
        { name: "Link4", component: Component1 },
      ],
      footerLink5: [
        { name: "Link5", component: Component1 },
      ],
    },
  ],
  display: true // Set false to hide this section, defaults to true;
};
*/

// Achievement Section
// Include certificates, talks etc

/*const achievementSection = {
  title: emoji("Reasearch Fields"),
  subtitle:
    "",

  achievementsCards: [
    {
      title: "Catalysis",
      subtitle:
        "mat4nrg conducts research in the field of catalysis. Our efforts concern different processes and components, such as:",
      image: require("./assets/images/becher.png"),
      imageAlt: "",
      footerLink1: [
        {
          name: "Catalysts Preperation",
          url: ""
        }],
        footerLink2: [
          {
            name: "Photocatalysis",
            url: ""
          },
        ],
        footerLink3: [
          {
          name: "Electrocatalysis",
          url: ""
          },],
        footerLink4: [
          {
          name: "Catalytic Tests",
          url: ""
         },],
         footerLink5: [
          {
          name: "+",
          url: ""
         },]
         ,
    },
    {
      title: "Energy Applications",
      subtitle:
        "mat4nrg conducts research in the field of energy engineering. Our efforts concern different processes and components, such as:",
      image: require("./assets/images/becher.png"),
      imageAlt: "t",
      footerLink1: [
        {
          name: "Novel Materials for Energy Applications",
          url: ""
        }],
        footerLink2: [
          {
            name: "Solid Oxide Fuel Cells (SOFCs)",
            url: ""
          },
        ],
        footerLink3: [
          {
          name: "Microbial Fuel Cells (MFCs)",
          url: ""
          },],
        footerLink4: [
          {
          name: "Energy Storage",
          url: ""
         },],
         footerLink5: [
          {
          name: "CO2 Conversion to Fuels and Chemicals",
          url: ""
         },]
    },
    {
      title: "Environment",
      subtitle: "mat4nrg conducts research in the field of Environment. Our efforts concern different processes and components, such as:",
      image: require("./assets/images/becher.png"),
      imageAlt: "t",
      footerLink1: [
        {
          name: "Plastics Degradation",
          url: ""
        }],
        footerLink2: [
          {
            name: "Advanced Oxidation Processes (AOPs)",
            url: ""
          },
        ],
        footerLink3: [
          {
          name: "Microbial FCs",
          url: ""
          },],
        footerLink4: [
          {
          name: "+",
          url: ""
         }],
         footerLink5: [
          {
          name: "+",
          url: ""
         }]}],
  
  display: true // Set false to hide this section, defaults to true;
};
/*const achievementSection = {
  title: "Research Fields",
  subtitle: "",

  achievementsCards: [
    {
      title: "Catalysis",
      subtitle: "mat4nrg conducts research in the field of catalysis. Our efforts concern different processes and components, such as:",
      image: require("./assets/images/becher.png"),
      imageAlt: "",
      footerLink1: [
        { name: "Catalysts Preparation", component: CatalystsPreparation },
      ],
      footerLink2: [
        { name: "Photocatalysis", component: Photocatalysis },
      ],
      footerLink3: [
        { name: "Electrocatalysis", component: Electrocatalysis },
      ],
      footerLink4: [
        { name: "Catalytic Tests", component: CatalyticTests },
      ],
      footerLink5: [
        { name: "+", component: () => <div>More Information</div> },
      ],
    },
    {
      title: "Energy Applications",
      subtitle: "mat4nrg conducts research in the field of energy engineering. Our efforts concern different processes and components, such as:",
      image: require("./assets/images/becher.png"),
      imageAlt: "t",
      footerLink1: [
        { name: "Novel Materials for Energy Applications", component: NovelMaterials },
      ],
      footerLink2: [
        { name: "Solid Oxide Fuel Cells (SOFCs)", component: SOFCs },
      ],
      footerLink3: [
        { name: "Microbial Fuel Cells (MFCs)", component: MFCs },
      ],
      footerLink4: [
        { name: "Energy Storage", component: EnergyStorage },
      ],
      footerLink5: [
        { name: "CO2 Conversion to Fuels and Chemicals", component: CO2Conversion },
      ],
    },
    {
      title: "Environment",
      subtitle: "mat4nrg conducts research in the field of Environment. Our efforts concern different processes and components, such as:",
      image: require("./assets/images/becher.png"),
      imageAlt: "t",
      footerLink1: [
        { name: "Plastics Degradation", component: PlasticsDegradation },
      ],
      footerLink2: [
        { name: "Advanced Oxidation Processes (AOPs)", component: AOPs },
      ],
      footerLink3: [
        { name: "Microbial FCs", component: MFCs },
      ],
      footerLink4: [
        { name: "+", component: () => <div>More Information</div> },
      ],
      footerLink5: [
        { name: "+", component: () => <div>More Information</div> },
      ],
    },
  ],

  display: false, // Set false to hide this section, defaults to true;
};
*/
// Blogs Section

const blogSection = {
  title: "Legal Notice",
  subtitle:
    "",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: '/LegalNotice',
      title: "Click here to read more about the Legal Notice",
      description:
        ""
    },
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "Legal Notice",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false// Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Kontaktieren Sie uns ☎️"),
  subtitle:
    ["mat4nrg GmbH ",
      "Burgstätterstr.42",
       "38678 Clausthal-Zellerfeld",
       "Germany"],
  number: "+49 5323 830863",
  email_address: "info@mat4nrg.de"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
