/*import React from 'react';
import "../../App.scss";

function NovelMaterialsforEnergyApplications() {
  return (
    <div style={{ height: '100%', overflow: 'scroll' }}> 
      <a href="/">
    <img  src={require("../header/logo.png")} alt={"Test"} width="100px" height="50px"  />
    </a>
    <p>Heutzutage besteht ein enormer Bedarf an neuartigen Materialien für ein breites Spektrum von Energieanwendungen. Anwendungsbereiche können sein:<br/>

<b>Wasserstofferzeugung und -speicherung</b><br/>
<b>Brennstoffzellen-Technologie</b><br/>
<b>Solarenergie</b><br/>
<b>Windenergie</b><br/>
<b>Steigerung der Energieeffizienz in Gebäuden und Integration alternativer Energiequellen</b><br/>
<b>Steigerung der Effizienz von Kraftwerken</b><br/>
<b>Geothermische Anwendungen</b><br/>
<b>Pumpspeicherkraftwerke in der Wasserkraft</b><br/></p>
      </div>
  );
}

export default NovelMaterialsforEnergyApplications;*/
import React from 'react';
import "../../App.scss";
import { useTranslation } from 'react-i18next';

function NovelMaterialsforEnergyApplications() {
  const { t } = useTranslation();

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      <a href="/">
        <img src={require("../header/logo.png")} alt={"Test"} width="100px" height="50px" />
      </a>
      <p>{t('novelMaterialsIntro')}<br/>
        <b>{t('hydrogenProduction')}</b><br/>
        <b>{t('fuelCellTechnology')}</b><br/>
        <b>{t('solarEnergy')}</b><br/>
        <b>{t('windEnergy')}</b><br/>
        <b>{t('energyEfficiencyBuildings')}</b><br/>
        <b>{t('powerPlantEfficiency')}</b><br/>
        <b>{t('geothermalApplications')}</b><br/>
        <b>{t('pumpedStorage')}</b><br/>
      </p>
    </div>
  );
}

export default NovelMaterialsforEnergyApplications;
