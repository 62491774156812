import React from 'react';
import "../../App.scss";

function electroCatalyst() {
  return (
    <div style={{ height: '100%', overflow: 'scroll' }}> 
      <a href="/">
    <img  src={require("../header/logo.png")} alt={"Test"} width="100px" height="50px"  />
    </a>

      </div>
  );
}

export default electroCatalyst;

// Repeat similarly for Component2 to Component5