// AchievementCard.js
import React from "react";
import "./AchievementCard.scss";

export default function AchievementCard({ cardInfo, isDark, handleOpen }) {
  return (
    <div className={isDark ? "dark-mode certificate-card" : "certificate-card"}>
      <div className="certificate-image-div">
        <img
          src={cardInfo.image}
          alt={cardInfo.imageAlt || "Card Thumbnail"}
          className="card-image"
        />
      </div>
      <div className="certificate-detail-div">
        <h5 className={isDark ? "dark-mode card-title" : "card-title"}>
          {cardInfo.title}
        </h5>
        <p className={isDark ? "dark-mode card-subtitle" : "card-subtitle"}>
          {cardInfo.subtitle}
        </p>
      </div>
      <div className="certificate-card-footer">
        {[...cardInfo.footer1, ...cardInfo.footer2, ...cardInfo.footer3, ...cardInfo.footer4, ...cardInfo.footer5].map((v, i) => (
          <span
            key={i}
            className={isDark ? "dark-mode certificate-tag" : "certificate-tag"}
            onClick={() => handleOpen(v.component)}
          >
            {v.name}
          </span>
        ))}
      </div>
    </div>
  );
}
